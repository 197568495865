// Gallery.jsx
import React from 'react';
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import {StaticImage} from "gatsby-plugin-image";
import Layout from "../components/layout";

const GalleryUK2000 = () => {
    return (
        <Layout>
            <a href="/Gallery">Return to Gallery</a>
            <h1>UK 2000 - 2010</h1>
            <h2>Dansha gradings 2010</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/Danshagradings2010/1947247_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/Danshagradings2010/573849_orig.jpg"
                alt="Sensei Ravey, Jo Golland, John Lynock, James Turner, Jermaine Gordan, Sensei Simon Golland, Alex Jenkinson."/><StaticImage
                src="../images/gallery/UK2000/Danshagradings2010/7275900_orig.jpg" alt="Sanchin shime"/><StaticImage
                src="../images/gallery/UK2000/Danshagradings2010/9999840_orig.jpg"
                alt="Kady Lilley (front) Jnr Shodan grading including 8 man fight"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Sunday sessions 2009 and 2010</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/Sundaysessions2009and2010/2095983_orig.jpg"
                             alt="Sensei Ravey / Sensei Goland. 1 on 1 training at Sheffield Goju Ryu"/><StaticImage
                src="../images/gallery/UK2000/Sundaysessions2009and2010/5724653_orig.jpg"
                alt="Chishi training at Shefield Goju Ryu"/><StaticImage
                src="../images/gallery/UK2000/Sundaysessions2009and2010/6654103_orig.jpg"
                alt="Chishi training at Shefield Goju Ryu"/><StaticImage
                src="../images/gallery/UK2000/Sundaysessions2009and2010/9587752_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>IBA Seminar 2008, 2009</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/IBASeminar20082009/2080596_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/IBASeminar20082009/6959859_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/IBASeminar20082009/7446594_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Dansha Grading 2009</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/DanshaGrading2009/1052321_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/DanshaGrading2009/4032318_orig.jpg"
                alt="Dansha grading, March 2009. Steve Dobson 1st Dan, Graeme Sampson 1st Dan, Sensei Ravey, Simon Gollan 4th Dan."/><StaticImage
                src="../images/gallery/UK2000/DanshaGrading2009/6480445_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/DanshaGrading2009/8606467_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>July 2007</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/July2007/558242_orig.jpg"
                             alt="Sensei Ravey's Mastin Moor dojo"/><StaticImage
                src="../images/gallery/UK2000/July2007/5948334_orig.jpg"
                alt="Sensei Ravey's Brimmington dojo"/><StaticImage
                src="../images/gallery/UK2000/July2007/7085356_orig.jpg"
                alt="Sensei Ravey's Brimmington dojo"/>
                <StaticImage
                    src="../images/gallery/UK2000/July2007/8119937_orig.jpg"
                    alt="Sensei Ravey's Mastin Moor dojo"></StaticImage>
            </SRLWrapper></SimpleReactLightbox>
            <h2>March 2007</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/March2007/1412011_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/March2007/2746369_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/March2007/3881647_orig.jpg"
                alt="Sensei Ravey teaching with IOGKF students in Sheffield"/><StaticImage
                src="../images/gallery/UK2000/March2007/905229_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>August 2006</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/August2006/1021563_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/August2006/2180944_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/August2006/5105928_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/August2006/9767949_orig.jpg"
                alt="Sensei Ravey and Helmut - park training"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>July 2006</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/July2006/176604_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/July2006/2990086_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/July2006/6362644_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK2000/July2006/8188803_orig.jpg"
                alt="Sensei Ravey teaching Sanchin kata at the Sheffield Shotokan Karate Dojo"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>2005</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/2005/2859467_orig.jpg"
                             alt="Sensei Simon Golland, Sensei Linda Marchant. IOGKF Spain Gasshuku 2005"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>2003</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK2000/2003/4680685_orig.jpg"
                             alt="Sensei Simon, Sensei Higaonna at Sensei George Andrews' dojo (IOGKF), London"/><StaticImage
                src="../images/gallery/UK2000/2003/5547674_orig.jpg"
                alt="Sensei Terauchi and Senei Golland at Sensei George Andrew's London Dojo"/><StaticImage
                src="../images/gallery/UK2000/2003/5599618_orig.jpg"
                alt="Sensei Andy Barker, Sensei George Andrews, Sensei Morio Higoanna, Sensei Simon Golland. London."/>
            </SRLWrapper></SimpleReactLightbox>
        </Layout>
    );
}

export default GalleryUK2000;
